
































































import Vue from 'vue';
import { mdiHome } from '@mdi/js';

export default Vue.extend({
  name: 'App',

  data: () => ({
    appTitle: 'SERICE',
    sidebar: false,
    menuItems: [
        { title: 'Home', path: '/', icon: mdiHome },
        { title: 'Site Characterization', pages: [
          { title: 'MASWaves', path: '/maswaves' },
          { title: 'Velocity Profiles', path: '/velocity-profiles' },
        ]},
        { title: 'Ground Motion', pages: [
          { title: 'Time Histories', path: '/time-histories' },
        ]},
        { title: 'Vulnerability', pages: [
          { title: 'Vulnerability Functions', path: '/vulnerability-functions' },
          { title: 'Fragility Functions', path: '/fragility-functions' },
          { title: 'Routines', path: '/routines' },
        ]},
        { title: 'Publications', path: '/publications'},
    ],
  }),

  created() {
    document.title = 'SSC-ICE';
  },
});
